<template>
  <div class="bg-white px-4 py-5 text-left shadow sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <h3 class="text-lg font-medium leading-6 text-gray-900">
          Change Password
        </h3>
      </div>
      <div class="mt-5 md:col-span-2 md:mt-0">
        <form>
          <div class="form-wrapper">
            <div class="form-item">
              <label
                for="email"
                class="block text-left text-sm font-medium text-gray-700"
                >Email</label
              >
              <div class="mt-1">
                <input
                  type="email"
                  name="email"
                  :value="currentUser.Username"
                  disabled="disabled"
                  class="block w-full cursor-not-allowed rounded-md border-gray-300 opacity-25 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="you@example.com"
                />
              </div>
            </div>

            <div class="form-item">
              <label
                for="email"
                class="block text-left text-sm font-medium text-gray-700"
                >Password</label
              >
              <div class="mt-1">
                <el-input
                  type="email"
                  name="email"
                  v-model="password"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Please input password"
                  show-password
                />
                <p class="mt-2 flex text-sm text-red-600">
                  <ExclamationCircleIcon
                    v-if="errors.password"
                    class="mr-2 h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                  {{ errors.password }}
                </p>
              </div>
            </div>

            <div class="form-item">
              <label
                for="email"
                class="block text-left text-sm font-medium text-gray-700"
                >Confirm Password</label
              >
              <div class="mt-1">
                <el-input
                  type="email"
                  name="email"
                  v-model="confirmPassword"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Please input password"
                  show-password
                />
                <p class="mt-2 flex text-sm text-red-600">
                  <ExclamationCircleIcon
                    v-if="errors.confirmPassword"
                    class="mr-2 h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                  {{ errors.confirmPassword }}
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="mt-4 flex justify-end">
    <button
      type="button"
      class="hover:bg-gray-5 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm"
    >
      Cancel
    </button>
    <button
      type="submit"
      @click="onSubmit"
      class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm"
    >
      Save
    </button>
  </div>
</template>

<script>
import { reactive, computed } from "vue";
import { useStore } from "vuex";

import { SECURITY_SCHEMA } from "@/common/schemas/account/password";
import { useField, useForm } from "vee-validate";

import { ExclamationCircleIcon } from "@heroicons/vue/solid";

export default {
  components: {
    ExclamationCircleIcon,
  },
  setup() {
    const store = useStore();

    const {
      user: { currentUser },
    } = store.state;

    const schema = computed(() => {
      return SECURITY_SCHEMA;
    });

    const state = reactive({
      password: "",
      confirmPassword: "",
    });

    const { errors, handleSubmit } = useForm({
      validationSchema: schema.value,
      validateOnMount: false,
      initialValues: state,
    });

    const { value: password } = useField("password");
    const { value: confirmPassword } = useField("confirmPassword");

    const onSubmit = handleSubmit((values) => {
      console.log(values);
    });

    return {
      errors,
      state,
      password,
      currentUser,
      confirmPassword,
      onSubmit,
    };
  },
};
</script>

<style>
.layout {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 24px;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
  overflow-y: scroll;
  width: 100%;
  position: relative;
}

.form-wrapper {
  max-width: 640px;
  margin: 0 auto;
}

.form-item {
  margin-bottom: 24px;
  width: 320px;
}
</style>
